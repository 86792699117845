import React from "react";
import { Button } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom';
import '../Home.css';

class Games extends React.Component {

  constructor(props) {
    super(props)
    document.addEventListener('mousemove',this.update)
    document.addEventListener('touchmove',this.update)
  }

  update(e){
   var x = e.clientX || e.touches[0].clientX
   var y = e.clientY || e.touches[0].clientY

   document.documentElement.style.setProperty('--cursorX', x + 'px')
   document.documentElement.style.setProperty('--cursorY', y + 'px')
  }

  getPaper() {
    window.location.href='../bellaspaper.jpg'
  }

  render() {
 return (<div id="container" class="App-header">
      <div class="mysterious-logo" style={{marginBottom:"40px"}}><img src="bellasinvitation.jpg" alt="Bella's Invitation" style={{maxWidth:"500px"}} /></div>
        <div class="subtitle" style={{position:"absolute", x:"auto", y:"auto", textAlign: "center", marginTop: "80px"}}>
           As seen in the New York Times <br /><br />
          <Button onClick={this.getPaper}>Read the Paper</Button>
        </div>
    </div>);
  }

}

export default withRouter(Games)

