import React from "react";
import '../Home.css';
import { Button, Modal, Image, Header, Input, Icon  } from 'semantic-ui-react'
import {withRouter} from 'react-router-dom';


class Home extends React.Component {

  constructor(props) {
  	super(props)
  	this.inputtext = ""
  	document.addEventListener('mousemove',this.update)
    document.addEventListener('touchmove',this.update)
  }

  update(e){
   var x = e.clientX || e.touches[0].clientX
   var y = e.clientY || e.touches[0].clientY

   document.documentElement.style.setProperty('--cursorX', x + 'px')
   document.documentElement.style.setProperty('--cursorY', y + 'px')
  }

  setInputText(e) {
  	this.inputtext = e
  }

  nextPath(path) {
  	if (this.inputtext.toLowerCase().replace(/ /g, '')=="longestroad") {
    	this.props.history.push(path)
	}
	else {
		alert("Uh, uh, uh... You didn't say the magic word.")
	}
  }

  render() {
  return (<div id="container" class="App-header">
  		<div class="mysterious-logo" style={{marginBottom:"40px"}}>mysterious</div>
  		  <Modal trigger={<Button>Enter</Button>}>
		    <Modal.Header inverted>One, Two, Three...</Modal.Header>
		    <Modal.Content>
		     	  <Input
		     	    onChange={e => this.setInputText(e.target.value)}
		     	    ref={input => this.inputtext = input}
				    icon='lock'
				    iconPosition='left'
				    label={{ tag: true, content: 'Passphrase' }}
				    labelPosition='right'
				    placeholder='Passphrase'
				  />
				  <br />
		    </Modal.Content>
		       <Modal.Actions>
		      <Button onClick={() => this.nextPath('/games')} primary>
		        Start<Icon name='chevron right' />
		      </Button>
		    </Modal.Actions>
		  </Modal>
  	</div>);
  }

}

export default withRouter(Home)

